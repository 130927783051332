import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="I’m David and I’m a Backend & Frontend engineer!" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>More about me</h1>
          <p>
            I specialize in Full Stack development. My stack in professional settings is JS/PHP/MySQL but I've been
            branching out to Node and NoSQL. Currently I'm building apps in React while looking for my next position.
            Check it out in link above!
            {/* eslint-disable-next-line prettier/prettier */}
            <a href="https://www.dropbox.com/s/9b0z4gia0zq03lq/David%20Langstein%20resume.docx?dl=1"> Click here to download my resume!</a>
          </p>
          <Button as={AnchorLink} href="#contact">
            Hire me
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
